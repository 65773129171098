<template>

    <div class="four-zero-three">
      <div class="four-zero-three__inner">
        <div class="four-zero-three__title">403</div>
        <div class="four-zero-three__desc">Acceso Denegado</div>
        <div class="four-zero-three__content">Lo sentimos, usted no tiene acceso a este módulo.</div>
      </div>
    </div>

</template>

<script>



	export default {
		data() {
			return {

			}
		},
		components:{

		},
		mounted() {

		},
		methods :{

		}
	}
</script>

<style lang="scss" scoped>




</style>
